import React from "react"
import { ErrorMessage, useField } from "formik"
import styled from "styled-components"
const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <MessageForm>
      <Label htmlFor={field.name}>{label}</Label>
      {props.type === "textarea" ? (
        <textarea
          className={`form-control shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          autoComplete="off"
        />
      ) : (
        <input
          className={`form-control message shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          autoComplete="off"
        />
      )}

      <ErrorMessage component="div" name={field.name} className="error" />
    </MessageForm>
  )
}

export default TextField
const MessageForm = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.borderMap};
  outline: 0;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.text};
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
`
const Label = styled.label`
  border: none;
  background: transparent;
`
