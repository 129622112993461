import styled from "styled-components"

const CeliasH3 = styled.h3`
  font-size: calc(0.6rem + 0.6vw);
  color: ${({ theme }) => theme.text};
  font-family: "Celias";
  text-transform: capitalize;
  font-weight: bold;
  :active {
    color: ${({ theme }) => theme.green};
  }
  @media (max-width: 768px) {
    font-size: calc(0.8em + 0.6vw);
  }
`

export default CeliasH3
