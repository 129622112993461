import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

import H1 from "../../components/Typography/H1/H1"
const ContactUsHeroBg = ({ className }) => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "contactformbg.webp" }) {
          childImageSharp {
            fluid(maxWidth: 2600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(relativePath: { eq: "contactformbg.webp" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 491px)`,
    },
  ]

  return (
    <BackgroundImage
      Tag={`section`}
      id={`media-test`}
      className={className}
      fluid={sources}
    >
      <StyledInnerWrapper>
        <WhiteH1>Contact Us</WhiteH1>
      </StyledInnerWrapper>
    </BackgroundImage>
  )
}

const StyledInnerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: left;
  padding: 15vh 0;
`

const StyledContactUsHeroBg = styled(ContactUsHeroBg)`
  background: no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 90vh;
  top: -20%;
  @media (max-width: 768px) {
    min-height: 80vh;
    padding-top: 50px;
  }
`

const WhiteH1 = styled(H1)`
  opacity: 1;
  color: #e8eaeb;
  padding-top: 12vh;
  padding-left: 16vw;
  padding-bottom: 0;
  margin-bottom: 0;
`
export default StyledContactUsHeroBg
