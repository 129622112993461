import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import StyledContactUsHeroBg from "../containers/ContactUs/HeroContactUs"
import { Flex, Box } from "reflexbox"
import WrapperContainers from "../components/WrapperContainer"
import styled from "styled-components"
import CenterH2 from "../components/Typography/H2/CenterH2"
import ContactForm from "../components/contactForm"
import H2 from "../components/Typography/H2/H2"
import { Location } from "@styled-icons/evil"
import { Telephone } from "@styled-icons/bootstrap"
import { Send } from "@styled-icons/feather"
import { Taxi } from "@styled-icons/boxicons-regular"
import CeliasH3 from "../components/Typography/H3/CeliasH3"
import Map from "../containers/ContactUs/Map"
import P from "../components/Typography/P/P"

const GreenLoc = styled(Location)`
  color: #29d9d5;
  width: 50px;
  position: absolute;
  left: -15px;
  top: 10px;
`
const GreenPhone = styled(Telephone)`
  color: #29d9d5;
  width: 35px;
  position: absolute;
  left: -6px;
  top: 3px;
`
const GreenSend = styled(Send)`
  color: #29d9d5;
  width: 40px;
  position: absolute;
  left: -10px;
  top: 3px;
`
const GreenTaxi = styled(Taxi)`
  color: #29d9d5;
  width: 60px;
  display: block;
  margin: auto;
`
const GreyBg = styled.div`
  background-color: ${({ theme }) => theme.greySection};
`
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`
const H2Center = styled(CenterH2)`
  text-align: center;
`
const Border = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.borderMap};
  box-sizing: border-box;
  width: 300px;
  margin: auto;
`
const WhiteBox = styled(Box)`
  background-color: ${({ theme }) => theme.body};
`
const Para = styled.p`
  padding-left: 2vw;
  padding-top: 1vh;
  padding-bottom: 0.8vh;
  font-family: "Celias";
  font-weight: medium;
  position: relative;
  @media (max-width: 768px) {
    padding-left: 10vw;
    padding-top: 0.8vh;
    padding-bottom: 0.6vh;
  }
`
const Number = styled.span`
  font-weight: bold;
`
const Call = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.headers};
`
const Mail = styled.a`
  text-decoration: none;
  color: #29d9d5;
`
const Pmap = styled(P)`
  margin: auto;
  text-align: center;
  font-size: calc(0.6rem + 0.4vw);
`
const Download = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.headers};
`
const ContactUs = () => (
  <GreyBg>
    <Layout>
      <Seo title="Contact Us" />
      <StyledContactUsHeroBg />
      <Section>
        <Flex flexWrap="wrap">
          <Box width={[1, 1]} p={3}>
            <H2Center>Let's Talk Business!</H2Center>
            <Border />
          </Box>
          <Box width={[1, 1 / 2]} p={3}>
            <CeliasH3>
              If you are interested in working with us,
              <br /> please get in touch.
            </CeliasH3>
            <Para>
              {" "}
              <GreenLoc />
              366 Moo 4, Export Processing Zone 3<br />
              Lat Kra Bang Industrial Estate, <br />
              Bangkok, 10520 Thailand
            </Para>
            <br />
            <Para>
              {" "}
              <GreenPhone />
              <Call href="tel:+66232609857">
                <Number>+66 (0) 2326-0985-7</Number>
              </Call>
            </Para>
            <br />
            <Para>
              {" "}
              <GreenSend />
              <Mail href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#97;&#100;&#118;&#97;&#110;&#99;&#101;&#64;&#97;&#100;&#118;&#109;&#102;&#114;&#46;&#99;&#111;&#109;">
                &#97;&#100;&#118;&#97;&#110;&#99;&#101;&#64;&#97;&#100;&#118;&#109;&#102;&#114;&#46;&#99;&#111;&#109;
              </Mail>
            </Para>{" "}
            <Flex flexWrap="wrap">
              <Box width={[1 / 2, 1 / 2]} p={3} pt={[0, 0, 5]}>
                <Pmap>
                  <GreenTaxi />
                  Map <br />
                  How to Get to Lat Kra Bang
                  <br /> Industrial Estate
                  <br />{" "}
                  <Download href="https://res.cloudinary.com/denq2jckc/image/upload/v1633327268/Map/Map-to-LKB-Industrial-Estate_qkaqj0.jpg">
                    Download&nbsp;
                  </Download>
                </Pmap>
              </Box>
              <Box width={[1 / 2, 1 / 2]} p={3} pt={[0, 0, 5]}>
                <Pmap>
                  <GreenTaxi /> Map <br />
                  How to find us once you are inside <br />
                  Lat Kra Bang Industrial Estate
                  <br />{" "}
                  <Download href="https://res.cloudinary.com/denq2jckc/image/upload/v1633327261/Map/Way-to-Advance-Inside-LKB-Industrial-Estate_xq8p0s.jpg">
                    Download&nbsp;
                  </Download>
                </Pmap>
              </Box>
            </Flex>
          </Box>
          <WhiteBox width={[1, 1 / 2]} p={3}>
            <H2>Get in touch</H2>
            <ContactForm />
          </WhiteBox>
        </Flex>
      </Section>
      <Flex flexWrap="wrap">
        <Box width={[1, 1]} p={0}>
          <a
            href="https://www.google.com/maps/place/Advance+Manufacturers+Co.,+Ltd./@13.7783232,100.7858063,17z/data=!3m1!4b1!4m5!3m4!1s0x311d66400274d621:0xc1d6f88cbaf9a0ae!8m2!3d13.778318!4d100.787995"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Map />
          </a>
        </Box>
      </Flex>
    </Layout>
  </GreyBg>
)

export default ContactUs
