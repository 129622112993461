import React from "react"
import { Flex, Box } from "reflexbox"
import { send } from "emailjs-com"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import Toastify from "toastify-js"
import TextField from "./textField"
import styled from "styled-components"
import { SendPlane } from "@styled-icons/remix-fill"
import "toastify-js/src/toastify.css"

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const ContactForm = () => {
  const validate = Yup.object({
    name: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Name is required field"),
    website: Yup.string().url().required("Website is required field"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required field"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone is invalid")
      .required("Phone is required field"),
    message: Yup.string()
      .max(100, "Must be 100 characters or less")
      .required("Message is required field"),
  })

  const sendEmail = formInputs => {
    send(
      "service_i4t22xc",
      "template_a1a7flp",
      formInputs,
      "user_Ct0hJaS640m90sBqM6sYb"
    )
      .then(response => {
        console.log("SUCCESS!", response.status, response.text)
        if (response.status === 200) {
          Toastify({
            text: "Mail sent successfully",
            backgroundColor: "linear-gradient(to right, #29D9D5, #29D9D5)",
            duration: 2000,
          }).showToast()
        } else {
          Toastify({
            text: "Error in send email.",
            backgroundColor: "linear-gradient(to right, #29D9D5, #29D9D5)",
            duration: 2000,
          }).showToast()
        }
        setTimeout(function () {
          window.location.reload(1)
        }, 2000)
      })
      .catch(err => {
        Toastify({
          text: err,
          backgroundColor: "linear-gradient(to right, #29D9D5, #29D9D5)",
          duration: 2000,
        }).showToast()
      })
  }

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          website: "",
          email: "",
          phone: "",
          message: "",
        }}
        validationSchema={validate}
        onSubmit={values => {
          sendEmail(values)
        }}
      >
        {formik => (
          <Form>
            <Flex flexWrap="wrap">
              <Box width={[1, 1 / 2]} p={3}>
                <TextField label="First Name*" name="name" type="text" />
                <TextField label="Email Address*" name="email" type="email" />
              </Box>
              <Box width={[1, 1 / 2]} p={3}>
                <TextField label="Last Name*" name="lastname" type="text" />
                <TextField label="Phone*" name="phone" type="text" />
              </Box>
            </Flex>
            <Box width={[1, 1]} p={3}>
              <Message
                className="message"
                label="Message"
                name="message"
                type="textarea"
              />
              <br /> <br />
              <ButtonSend className="btn btn-dark" type="submit">
                <WhiteSend />
              </ButtonSend>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ContactForm

const Message = styled(TextField)`
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;

  color: #ddd;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  transition: border-color 0.2s;
  :focus {
    border-bottom-color: #ddd;
  }
`
const ButtonSend = styled.button`
  float: right;
  width: 70px;
  height: 70px;
  background: #29d9d5 !important;
  border-radius: 50%;
  border: none;
  :hover {
    background: #29d9d5 !important;
    opacity: 0.8;
  }
`
const WhiteSend = styled(SendPlane)`
  color: white;
  margin: auto;
  width: 40px;
  height: auto;
`
