import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Map = () => (
  <StaticImage
    src="../../images/contactmap.jpg"
    alt="Map contact advance manufacturers"
    placeholder="blurred"
    layout="fullWidth"
  />
)
export default Map
